<template>
  <div>
    <b-card>
      <!-- Media -->
      <b-media class="mb-2">
        <template #aside>
          <b-avatar
            ref="previewEl"
            :src="organizationData.avatar"
            :text="avatarText(organizationData.name)"
            :variant="`light-${resolveOrganizationRoleVariant(
              organizationData.role
            )}`"
            size="90px"
            rounded
          />
        </template>
        <h4 class="mb-1">
          {{ organizationData.name }}
        </h4>
      </b-media>
      <!-- Organization Info: Input Fields -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="mt-1">
            <b-col cols="12" lg="12" md="12" sm="12" xs="12" class="mt-3 mb-1">
              <h4>Organization Details</h4>
            </b-col>
            <b-col cols="12" md="4">
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group label="Name" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="organizationData.name"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}</b-form-invalid-feedback
                  >
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="4"
              v-if="organizationData.gcp_billing_account_id"
            >
              <validation-provider
                #default="validationContext"
                name="gcp billing account id"
                rules="required"
              >
                <b-form-group
                  label="Gcp Billing Account Id"
                  label-for="gcp_billing_account_id"
                >
                  <b-form-input
                    id="gcp_billing_account_id"
                    v-model="organizationData.gcp_billing_account_id"
                    readonly
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}</b-form-invalid-feedback
                  >
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="4" v-if="organizationData.cloud_customer_id">
              <b-form-group
                label="Cloud Customer Id"
                label-for="cloud_customer_id"
              >
                <b-form-input
                  id="cloud_customer_id"
                  v-model="organizationData.cloud_customer_id"
                  readonly
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4" v-if="organizationData.cloud_identity_id">
              <b-form-group
                label="Cloud Identity Id"
                label-for="cloud_identity_id"
              >
                <b-form-input
                  id="domacloud_identity_idin"
                  v-model="organizationData.cloud_identity_id"
                  readonly
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="Domain" label-for="domain">
                <b-form-input
                  id="domain"
                  v-model="organizationData.domain"
                  readonly
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <validation-provider
                #default="validationContext"
                name="region"
                rules="required"
              >
                <b-form-group label="Region" label-for="regionType">
                  <v-select
                    id="regionType"
                    v-model="organizationData.region_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="regionTypes.response"
                    :clearable="false"
                    label="name"
                    :reduce="(val) => val.id"
                    class="per-page-selector d-inline-block mx-50"
                    @input="getCurrency"
                  >
                    <span slot="no-options">{{ regionTypes.message }}</span>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}</b-form-invalid-feedback
                  >
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="4">
              <validation-provider
                #default="validationContext"
                name="currency"
                rules="required"
              >
                <b-form-group label="Currency" label-for="currencyType">
                  <v-select
                    id="currencyType"
                    v-model="organizationData.currency_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="currency.response"
                    :clearable="false"
                    label="name"
                    :reduce="(val) => val.id"
                    class="per-page-selector d-inline-block mx-50"
                  >
                    <span slot="no-options">{{ currency.message }}</span>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}</b-form-invalid-feedback
                  >
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="4">
              <validation-provider
                #default="validationContext"
                name="account manager"
                rules="required"
              >
                <b-form-group
                  label="Account Manager"
                  label-for="account_manager"
                >
                  <v-select
                    v-if="loggedinUserData.role_name !== 'ACCOUNT_MANAGER'"
                    id="account_manager"
                    v-model="organizationData.account_manager"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="accountManager.response"
                    :clearable="false"
                    label="display_name"
                    :reduce="(val) => val.uid"
                    class="per-page-selector d-inline-block mx-50"
                  >
                    <span slot="no-options">{{ accountManager.message }}</span>
                  </v-select>
                  <b-form-input
                    v-model="loggedinUserData.display_name"
                    readonly
                    v-if="loggedinUserData.role_name == 'ACCOUNT_MANAGER'"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="4">
              <validation-provider
                #default="validationContext"
                name="support tiers"
                rules="required"
              >
                <b-form-group label="Support Tiers" label-for="support_tiers">
                  <v-select
                    id="support_tiers"
                    v-model="organizationData.support_tier"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="supportTiers.response"
                    :clearable="false"
                    :reduce="(val) => val"
                    class="per-page-selector d-inline-block mx-50"
                  >
                    <span slot="no-options">{{ supportTiers.message }}</span>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="4">
              <validation-provider
                #default="validationContext"
                name="Country"
                rules="required"
              >
                <b-form-group label="Country" label-for="country">
                  <v-select
                    id="country"
                    v-model="organizationData.country_code"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="countries.response"
                    :clearable="false"
                    label="name"
                    :reduce="(val) => val.country_code"
                    class="per-page-selector d-inline-block mx-50"
                    @input="getState"
                  >
                    <span slot="no-options">{{ countries.message }}</span>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="State" label-for="state">
                <v-select
                  id="state"
                  v-model="organizationData.state_code"
                  v-if="
                    organizationData.country_code !== '' &&
                    state.response &&
                    state.response.length !== 0
                  "
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="state.response"
                  :clearable="false"
                  label="name"
                  :reduce="(val) => val.state_code"
                  class="per-page-selector d-inline-block mx-50"
                >
                  <span slot="no-options">{{ state.message }}</span>
                </v-select>
                <b-form-input
                  id="state_code"
                  v-if="
                    organizationData.country_code !== '' &&
                    state.response &&
                    state.response.length == 0
                  "
                  v-model="organizationData.state_code"
                  trim
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <validation-provider
                #default="validationContext"
                name="City"
                rules="required"
              >
                <b-form-group label="City" label-for="city">
                  <b-form-input
                    id="city"
                    v-model="organizationData.city"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="4">
              <validation-provider
                #default="validationContext"
                name="postal code"
                rules="required"
              >
                <b-form-group label="Postal Code" label-for="postal_code">
                  <b-form-input
                    id="postal_code"
                    v-model="organizationData.postal_code"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="4">
              <validation-provider
                #default="validationContext"
                name="language code"
                rules="required"
              >
                <b-form-group label="Language Code" label-for="language_code">
                  <v-select
                    id="language_code"
                    v-model="organizationData.language_code"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="languageCodes.response"
                    :clearable="false"
                    label="name"
                    :reduce="(val) => val.code"
                    class="per-page-selector d-inline-block mx-50"
                    @input="getState"
                  >
                    <span slot="no-options">{{ languageCodes.message }}</span>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="4">
              <validation-provider
                #default="validationContext"
                name="address"
                rules="required"
              >
                <b-form-group
                  label="Company Address"
                  label-for="company_address_lines1"
                >
                  <b-form-input
                    id="company_address_lines1"
                    v-model="organizationData.address_lines1"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}</b-form-invalid-feedback
                  >
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="4" v-if="organizationData.address_lines2">
              <b-form-group
                label="Company Address1"
                label-for="company_address_lines2"
              >
                <b-form-input
                  id="company_address_lines2"
                  v-model="organizationData.address_lines2"
                  trim
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4" v-if="organizationData.address_lines3">
              <b-form-group
                label="Company Address2"
                label-for="company_address_lines3"
              >
                <b-form-input
                  id="company_address_lines3"
                  v-model="organizationData.address_lines3"
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            class="mt-1"
            v-if="loggedinUserData.role_name == 'GLOBAL_ADMIN'"
          >
            <b-col cols="12" lg="12" md="12" sm="12" xs="12" class="mt-3 mb-1"
              ><h4>Discount Details</h4></b-col
            >
            <b-col cols="12" md="4">
              <validation-provider
                #default="validationContext"
                name="google cloud discount"
                rules="required|between:0,15"
              >
                <b-form-group
                  label="Google Cloud Discount"
                  label-for="google_cloud_discount"
                >
                  <b-input-group>
                    <b-form-input
                      id="google_cloud_discount"
                      v-model="organizationData.google_cloud_discount"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                    <b-input-group-append is-text>
                      <feather-icon icon="PercentIcon" />
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="4">
              <validation-provider
                #default="validationContext"
                name="google workspace discount"
                rules="required|between:0,15"
              >
                <b-form-group
                  label="Google Workspace Discount"
                  label-for="google_workspace_discount"
                >
                  <b-input-group>
                    <b-form-input
                      id="google_workspace_discount"
                      v-model="organizationData.google_workspace_discount"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                    <b-input-group-append is-text>
                      <feather-icon icon="PercentIcon" />
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="12" lg="12" md="12" sm="12" xs="12" class="mt-3 mb-1"
              ><h4>Contact Details</h4></b-col
            >
            <b-col cols="12" md="4">
              <validation-provider
                #default="validationContext"
                name="first name"
                rules="required"
              >
                <b-form-group label="First Name" label-for="first_name">
                  <b-form-input
                    id="first_name"
                    v-model="organizationData.first_name"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="4">
              <validation-provider
                #default="validationContext"
                name="last name "
                rules="required"
              >
                <b-form-group label="Last Name" label-for="last_name">
                  <b-form-input
                    id="last_name"
                    v-model="organizationData.last_name"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="4">
              <b-row>
                <b-col class="pr-0">
                  <validation-provider
                    #default="validationContext"
                    name="email"
                    rules="required"
                  >
                    <b-form-group label="Email" label-for="email">
                      <b-form-input
                        id="email"
                        v-model="organizationData.emailFirst"
                        :state="
                          getValidationState(validationContext) &&
                          emailDomainName
                        "
                      />

                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback
                      >

                      <b-form-invalid-feedback :state="emailDomainName">
                        This email is part of a reserved domain. Please enter a
                        different email address.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col class="mt-2 pl-0">
                  <validation-provider
                    #default="validationContext"
                    name="domain name"
                    rules="required"
                  >
                    <b-input-group prepend="@">
                      <b-form-input
                        id="domain"
                        v-model="organizationData.emailLast"
                        readonly
                      />
                    </b-input-group>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col></b-row
              >
            </b-col>

            <b-col cols="12" md="4">
              <validation-provider
                #default="validationContext"
                name="alternate email"
                rules="email"
              >
                <b-form-group
                  label="Alternate Email"
                  label-for="alternate_email"
                >
                  <b-form-input
                    id="alternate_email"
                    v-model="organizationData.alternate_email"
                    :state="alternateEmailValidation"
                    trim
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback :state="alternateEmailValidation">
                    Email and alternate email could not be same
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="Phone" label-for="phone">
                <b-form-input
                  id="phone"
                  v-model="organizationData.phone_number"
                  trim
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="Role" label-for="Role">
                <b-form-input v-model="role" readonly />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
            :disabled="
              organizationData.name == '' ||
              organizationData.currency_id == '' ||
              organizationData.region_id == '' ||
              organizationData.support_tier == '' ||
              organizationData.country_code == '' ||
              organizationData.city == '' ||
              organizationData.postal_code == '' ||
              organizationData.language_code == '' ||
              organizationData.address_lines1 == '' ||
              organizationData.first_name == '' ||
              organizationData.last_name == '' ||
              organizationData.emailFirst == ''
            "
          >
            <div class="d-flex align-items-center">
              <b-spinner
                small
                label="Text Centered"
                v-if="isLoading"
              ></b-spinner>
              <span class="button-title">Save Changes</span>
            </div>
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>

    <subscription
      v-if="organizationData"
      :organization-data="organizationData"
    ></subscription>
  </div>
</template>
<script>
import {
  BImg,
  BModal,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroupAppend,
  BInputGroup,
  BTable,
  BCard,
  BCardText,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BSpinner,
  BFormInvalidFeedback,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import useOrganizationsList from "../organizations-list/useOrganizationsList";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import router from "@/router";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, regex, between } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import axios from "@axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Subscription from "@core/components/subscription/Subscription.vue";

export default {
  components: {
    Subscription,
    Loading,
    BImg,
    BModal,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroupAppend,
    BInputGroup,
    BTable,
    BCard,
    BCardText,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BSpinner,
    BFormInvalidFeedback,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      regex,
      between,
      email,
      state: {},
      currency: {},
      noOptionMessage: "Sorry, data is loading",
      noDataMessage: "Sorry, data is not avaialble",
    };
  },
  props: {
    organizationData: {
      type: Object,
      required: true,
    },
    currencyTypes: {
      type: Object,
      required: true,
    },
    regionTypes: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    languageCodes: {
      type: Object,
      required: true,
    },
    accountManager: {
      type: Object,
      required: true,
    },
    supportTiers: {
      type: Object,
      required: true,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    organizationNames() {
      return store.getters["app-organization/organizationsList"];
    },
    emailDomainName() {
      return this.organizationData.emailFirst.includes("@") ? false : true;
    },
    alternateEmailValidation() {
      if (this.organizationData.alternate_email) {
        return this.organizationData.alternate_email.includes(
          this.organizationData.emailLast
        )
          ? false
          : true;
      }
    },
  },
  beforeMount() {
    axios
      .get(`/currency/region/${this.organizationData.region_id}`)
      .then((response) => {
        const currencyName = [];
        for (const [key, value] of Object.entries(response.data)) {
          currencyName.push(value);
        }
        const currencyObject = {
          response: currencyName,
          message:
            currencyName.length == 0
              ? this.noDataMessage
              : this.noOptionMessage,
        };
        this.currency = currencyObject;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`/region/countries/${this.organizationData.country_code}/states`)
      .then((response) => {
        const stateName = [];
        for (const [key, value] of Object.entries(response.data.states)) {
          stateName.push(value);
        }
        const stateObject = {
          response: stateName,
          message:
            stateName.length == 0 ? this.noDataMessage : this.noOptionMessage,
        };
        this.state = stateObject;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    getCurrency() {
      this.organizationData.currency_id = "";
      axios
        .get(`/currency/region/${this.organizationData.region_id}`)
        .then((response) => {
          const currencyName = [];
          for (const [key, value] of Object.entries(response.data)) {
            currencyName.push(value);
          }
          const currencyObject = {
            response: currencyName,
            message:
              currencyName.length == 0
                ? this.noDataMessage
                : this.noOptionMessage,
          };
          this.currency = currencyObject;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getState() {
      this.organizationData.state_code = "";
      axios
        .get(`/region/countries/${this.organizationData.country_code}/states`)
        .then((response) => {
          const stateName = [];
          for (const [key, value] of Object.entries(response.data.states)) {
            stateName.push(value);
          }
          const stateObject = {
            response: stateName,
            message:
              stateName.length == 0 ? this.noDataMessage : this.noOptionMessage,
          };
          this.state = stateObject;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  setup(props, { emit }) {
    const toast = useToast();
    let isLoading = ref(false);
    let role = ref("ORG_ADMIN");
    let domainDataName = ref([]);
    // let domainName = ref("");
    let domainNameSelected = ref([]);
    let commonDomian = ref([]);
    const loggedinUserData = JSON.parse(localStorage.getItem("userData"));

    const { resolveOrganizationRoleVariant } = useOrganizationsList();

    store
      .dispatch("app-organization/getDomainName", props.organizationData.id)
      .then((response) => {
        if (response.data.name) {
          for (const [key, value] of Object.entries(response.data.name)) {
            domainDataName.value.push(value);
            domainNameSelected.value.push(value);
          }
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `There is no domain names available`,
          },
        });
      });

    const onSubmit = () => {
      isLoading.value = true;
      props.organizationData.email =
        props.organizationData.emailFirst +
        "@" +
        props.organizationData.emailLast;

      commonDomian.value = domainDataName.value.filter(
        (x) => !domainNameSelected.value.includes(x)
      );
      store
        .dispatch("app-organization/updateOrganization", props.organizationData)
        .then((response) => {
          const domainData = {
            id: response.data.id,
            name: commonDomian.value,
          };
          if (commonDomian.value.length > 0) {
            store
              .dispatch("app-organization/addDomainName", domainData)
              .then(() => {
                toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Success `,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `Domain name added successfully`,
                  },
                });
              })
              .catch(() => {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                    text: `Domain name could not added`,
                  },
                });
              });
          }
          isLoading.value = false;
          emit("refetch-data");
          toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success `,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Organization updated successfully`,
            },
          });
          router.push("/apps/organizations/list"); // redirect to the feed
        })
        .catch(() => {
          isLoading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `Organization could not update`,
            },
          });
        });
    };
    const yourMethod = (value) => {
      domainName.value = "";
      domainDataName.value.push(value);
    };
    const deleteDomain = (domainName) => {
      for (const [key, value] of Object.entries(domainDataName.value)) {
        if (value === domainName) {
          domainDataName.value.splice(key, 1);
        }
      }
      if (
        props.organizationData.email_domain.includes(domainName) &&
        domainDataName.value.includes(domainName)
      ) {
        const domainData = {
          id: props.organizationData.id,
          name: domainName,
        };
        store
          .dispatch("app-organization/deleteDomainName", domainData)
          .then(() => {
            toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success `,
                icon: "CoffeeIcon",
                variant: "success",
                text: `Domain name deleted successfully`,
              },
            });
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: `Domain name could not deleted`,
              },
            });
          });
      }
    };
    const { refFormObserver, getValidationState } = formValidation();
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        props.organizationData.avatar = base64;
      }
    );
    return {
      loggedinUserData,
      isLoading,
      role,
      resolveOrganizationRoleVariant,
      avatarText,

      onSubmit,
      domainDataName,
      // domainName,
      yourMethod,
      deleteDomain,
      refFormObserver,
      getValidationState,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";
@import "../organizationStyle.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.v-select {
  margin: 0px !important;
  width: 100%;
}
.domain {
  color: #5e5873;
  border: 1px solid #b8c2cc;
  border-radius: 5px;
}

.domainName {
  padding: 5px;
  padding-right: 30px;
  padding-left: 10px;
}

.domainIcon {
  margin-top: 0.6rem;
  margin-right: 8px;
  cursor: pointer;
}
.feather {
  margin-top: 3px;
  margin-right: 6px;
}
.CheckCircleIcon {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 50px;
  margin-right: 50px;
}
.support-hours-message {
  font-size: 0.857rem;
}
</style>
