<template>
  <b-card>
    <div>
      <h4 class="mb-1">Subscriptions</h4>
      <b-card-text>
        <div class="grid">
          <b-card id="newsubscriptions" @click="showModal">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                height="100%"
                version="1.1"
                viewBox="0 0 40 40"
                width="50%"
                fit=""
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  id="new-fab-icon"
                  stroke="none"
                  stroke-width="1"
                >
                  <g id="FAB/Regular/Raised">
                    <g id="Icons/GFAB/Create">
                      <g id="gFAB">
                        <polygon
                          fill="#4285F4"
                          id="Fill-2"
                          points="16.6666667 16.6666667 16.6666667 23.335 23.4383333 23.335 40 23.335 40 16.6666667"
                        ></polygon>
                        <polygon
                          fill="#FBBC05"
                          id="Fill-3"
                          points="16.6666667 16.6666667 0 16.6666667 0 23.335 16.6666667 23.335 23.335 16.6666667"
                        ></polygon>
                        <rect
                          fill="#34A853"
                          height="16.6666667"
                          id="Rectangle"
                          width="6.66666667"
                          x="16.6666667"
                          y="23.3333333"
                        ></rect>
                        <polygon
                          fill="#EA4335"
                          id="Fill-4"
                          points="16.6666667 0 16.6666667 23.3333333 23.335 16.6666667 23.335 0"
                        ></polygon>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <span class="mt-2 text-primary font-weight-bold">New Service</span>
          </b-card>

          <b-card id="newsubscriptions" v-if="loading">
            <loading
              :active.sync="loading"
              :can-cancel="true"
              :is-full-page="false"
              color="#4285f4"
              loader="dots"
            />
            <div v-show="!loading"></div>
          </b-card>

          <b-card
            id="subscriptions"
            v-for="(subscription, i) in subscriptionData"
            :key="i"
          >
            <div id="heading">
              <span class="d-flex justify-content-center">
                <b-img
                  :src="subscription.sku_details.logo_url"
                  width="30%"
                ></b-img>
              </span>
              <span class="subscriptionTitl">{{ subscription.sku_name }}</span>
              <span
                v-if="subscription.display_name"
                class="subscriptionName"
                v-b-tooltip.hover
                :title="subscription.display_name"
              >
                {{
                  subscription.display_name.length > 20
                    ? subscription.display_name.substring(0, 16) + "..."
                    : subscription.display_name
                }}
              </span>
              <span class="subscriptionName">
                {{ subscription.billing_account_id }}
              </span>
              <span
                class="subscriptionName"
                v-if="subscription.end_time !== 'None'"
              >
                Renewal
                {{
                  new Date(
                    subscription.end_time.slice(0, 10)
                  ).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })
                }}
              </span>
              <span class="subscriptionName" v-if="subscription.licenses">
                {{ subscription.licenses }}
              </span>

              <span class="CheckCircleIcon subscriptionName"
                ><feather-icon
                  icon="CheckCircleIcon"
                  size="16"
                  class="feather-success feather"
                />{{ subscription.state }}</span
              >
            </div>
          </b-card>
        </div>
      </b-card-text>
    </div>

    <b-modal
      ref="my-modal"
      no-close-on-backdrop
      hide-footer
      title="Add New Subscription"
    >
      <div class="d-block text-center">
        <v-select
          id="state"
          v-model="offer"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="offerTypes"
          :clearable="false"
          label="product_description"
          :reduce="(val) => val.id"
          class="per-page-selector d-inline-block mx-50"
          placeholder="Select subscription"
        >
        </v-select>
      </div>

      <div class="d-flex justify-content-end">
        <b-button
          class="mt-1 mr-1"
          variant="primary"
          :disabled="offer == ''"
          @click="
            (e) => {
              e.stopPropagation();
              addSubscription(offer);
              hideModal();
            }
          "
          >Add</b-button
        >
        <b-button class="mt-1" @click="hideModal">Cancel</b-button>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BImg,
  BModal,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BTable,
  BCard,
  BCardText,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BSpinner,
  BFormInvalidFeedback,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { ref } from "@vue/composition-api";
export default {
  components: {
    Loading,
    BImg,
    BModal,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BTable,
    BCard,
    BCardText,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BSpinner,
    BFormInvalidFeedback,

    vSelect,
  },
  props: {
    organizationData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      offer: "",
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
  setup(props, { emit }) {
    const token = localStorage.getItem("accessToken");
    const subscriptionData = ref([]);
    let offerTypes = ref([]);
    let loading = ref(false);

    if (props.organizationData.cloud_customer_id) {
      axios
        .get(
          `/channel/entitlements/${props.organizationData.region_id}/${props.organizationData.cloud_customer_id}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((response) => {
          for (const [key, value] of Object.entries(
            response.data.entitlements
          )) {
            subscriptionData.value.push(value);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    axios
      .get(`/channel/offers/${props.organizationData.region_id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        offerTypes.value = response.data.offers;
      })
      .catch((err) => {
        console.log(err);
      });

    const addSubscription = (offer) => {
      const subscriptionObj = JSON.stringify({
        offer_id: offer,
        org_display_name: props.organizationData.name,
      });

      loading.value = true;
      axios
        .post(
          `/channel/entitlements/${props.organizationData.region_id}/${props.organizationData.cloud_customer_id}`,
          subscriptionObj,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((response) => {
          getSubscriptions();
        })
        .catch((err) => {
          loading.value = false;
          console.log(err);
        });
    };

    const getSubscriptions = () => {
      subscriptionData.value = [];
      axios
        .get(
          `/channel/entitlements/${props.organizationData.region_id}/${props.organizationData.cloud_customer_id}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((response) => {
          loading.value = false;
          for (const [key, value] of Object.entries(
            response.data.entitlements
          )) {
            subscriptionData.value.push(value);
          }
        })
        .catch((err) => {
          loading.value = false;
          console.log(err);
        });
    };

    return {
      addSubscription,
      subscriptionData,
      offerTypes,
      loading,
    };
  },
};
</script>
<style>
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 200px));
  grid-gap: 20px;
  align-items: stretch;
}
#subscriptions {
  overflow: hidden;
  margin-bottom: 0;
  margin-top: 1rem;
  text-align: center;
}
#heading {
  position: relative;
  height: 230px;
}
#subscriptions .card-body {
  overflow: hidden;
  height: 100%;
  border: 1px solid #d8d6de;
  border-radius: 0.375rem;
}
#newsubscriptions {
  margin-bottom: 0;
  margin-top: 1rem;
}
#newsubscriptions .card-body {
  overflow: hidden;
  height: 100%;
  border: 1px solid #d8d6de;
  border-radius: 0.375rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.subscriptionTitl {
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  margin: 8px 0px;
}
.subscriptionName {
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
}
</style>
