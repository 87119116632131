<template>
  <component :is="organizationData === undefined ? 'div' : 'div'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="organizationData === undefined">
      <h4 class="alert-heading">Error fetching organization data</h4>
      <div class="alert-body">
        No organization found with this organization id. Check
        <b-link class="alert-link" :to="{ name: 'apps-organizations-list' }">
          Organization List
        </b-link>
        for other organizations.
      </div>
    </b-alert>
    <b-breadcrumb class="pl-0 pb-1">
      <b-breadcrumb-item :to="{ name: 'apps-support-cases-list' }">
        Organizations
      </b-breadcrumb-item>
      <b-breadcrumb-item active> Edit Organization </b-breadcrumb-item>
    </b-breadcrumb>
    <organization-edit-tab-account
      v-if="organizationData"
      :organization-data="organizationData"
      :currency-types="currencyTypes"
      :region-types="regionTypes"
      :countries="countries"
      :language-codes="languageCodes"
      :account-manager="accountManager"
      :support-tiers="supportTiers"
      class="pt-75"
    />
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import OrganizationEditTabAccount from "./OrganizationEditTabAccount.vue";
import organizationStoreModule from "../organizationStoreModule";
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BBreadcrumb,
    BBreadcrumbItem,
    OrganizationEditTabAccount,
  },
  setup() {
    const organizationData = ref(null);
    let currencyTypes = ref({});
    let regionTypes = ref({});
    let countries = ref({});
    let languageCodes = ref({});
    let accountManager = ref({});
    let supportTiers = ref({});
    let noOptionMessage = ref("Sorry, data is loading");
    let noDataMessage = ref("Sorry, data is not avaialble");

    const ORGANIZATION_APP_STORE_MODULE_NAME = "app-organization";
    // Register module
    if (!store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
      store.registerModule(
        ORGANIZATION_APP_STORE_MODULE_NAME,
        organizationStoreModule
      );
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
        store.unregisterModule(ORGANIZATION_APP_STORE_MODULE_NAME);
    });
    store.commit("appConfig/SPINNER_CONFIG", true);

    store
      .dispatch("app-organization/fetchOrganization", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        organizationData.value = response.data;
        organizationData.value.emailFirst =
          organizationData.value.email.split("@")[0];
        organizationData.value.emailLast =
          organizationData.value.email.split("@")[1];
        store.commit("appConfig/SPINNER_CONFIG", false);
      })
      .catch((error) => {
        store.commit("appConfig/SPINNER_CONFIG", false);
        if (error.response.status === 404) {
          organizationData.value = undefined;
        }
      });

    store
      .dispatch("app-organization/fetchCurrencyTypes")
      .then((response) => {
        const currencyTypesObject = {
          response: response.data,
          message: response.data.length == 0 ? noDataMessage : noOptionMessage,
        };
        currencyTypes.value = currencyTypesObject;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching currency types",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    store
      .dispatch("app-organization/fetchRegionTypes")
      .then((response) => {
        const regionTypesObject = {
          response: response.data,
          message: response.data.length == 0 ? noDataMessage : noOptionMessage,
        };
        regionTypes.value = regionTypesObject;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching region types",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    store
      .dispatch("app-organization/fetchCountries")
      .then((response) => {
        const countriesObject = {
          response: response.data.countries,
          message:
            response.data.countries.length == 0
              ? noDataMessage
              : noOptionMessage,
        };
        countries.value = countriesObject;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching countries",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    store
      .dispatch("app-organization/fetchLanguageCodes")
      .then((response) => {
        const languageCodesObject = {
          response: response.data.language_codes,
          message:
            response.data.language_codes.length == 0
              ? noDataMessage
              : noOptionMessage,
        };
        languageCodes.value = languageCodesObject;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching language codes",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    store
      .dispatch("app-organization/fetchAccountManager")
      .then((response) => {
        const accountManagerObject = {
          response: response.data,
          message: response.data.length == 0 ? noDataMessage : noOptionMessage,
        };
        accountManager.value = accountManagerObject;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching account manager",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    store
      .dispatch("app-organization/fetchSupportTiers")
      .then((response) => {
        const supportTiersObject = {
          response: response.data.support_tiers,
          message:
            response.data.support_tiers.length == 0
              ? noDataMessage
              : noOptionMessage,
        };
        supportTiers.value = supportTiersObject;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching support tiers",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    return {
      countries,
      organizationData,
      currencyTypes,
      regionTypes,
      languageCodes,
      accountManager,
      supportTiers,
    };
  },
};
</script>
